<div class="modal-body">
  <div class="mx-2">
    <h1>{{ data.title }}</h1>
    <div class="d-flex flex-row gap-1 align-items-center mb-3">
      <span *ngFor="let technology of data.technologies" class="badge rounded-pill bg-primary">{{ technology }}</span>
    </div>
    <p class="mb-3">{{ data.description }}</p>
    <ng-container *ngIf="!loading;else loadingSpinner">
      <ul class="list-group list-group-flush">
        <li *ngFor="let quizQuestion of questions; let i = index" class="list-group-item">
          <h5>Question {{ i + 1 }}</h5>
          <app-question-preview
            [data]="quizQuestion">
          </app-question-preview>
        </li>
      </ul>
    </ng-container>
  </div>
</div>


<ng-template #loadingSpinner>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
