<div class="card card-body mb-2">
  <div class="w-100 d-flex justify-content-between gap-2 align-items-center">
    <div class="mx-1 d-flex align-items-start">
      <button (click)="openFilterModal()" class="btn btn-light border me-2" type="button">
        <span class="bi bi-filter"></span>
        Filters
      </button>
      <button (click)="resetFilter(); loadContent()" *ngIf="hasFilter"
              class="btn btn-light btn-sm border text-muted align-self-end"
              type="button">
        <span class="bi bi-x-circle"></span>
        Clear Filters
      </button>
    </div>
    <div class="d-flex justify-content-start flex-row-reverse gap-2 align-items-end flex-grow-1">
      <div class="col-12 col-md-3">
        <form class="input-group">
          <input (input)="loadContent()"
                 [(ngModel)]="pagination.search"
                 class="form-control"
                 name="searchQuery"
                 placeholder="Search"
                 type="text"/>
          <button (click)="loadContent()"
                  class="btn btn-light border-light-subtle"
                  type="button">
            <span class="bi bi-search"></span>
            <span class="sr-only">Search</span>
          </button>
        </form>
      </div>
      <div ngbDropdown>
        <button class="btn btn-light border"
                ngbDropdownToggle
                ngbTooltip="Sort"
                type="button">
          <span class="bi bi-sort-alpha-down"></span>
          Sort by: <span class="mx-1">{{ pagination.sortBy | spaceWords | titlecase }}</span>
        </button>
        <div ngbDropdownMenu>
          <button (click)="pagination.sortBy = 'createdAt'; pagination.direction = 'desc'; loadContent()"
                  ngbDropdownItem>
            Newest First
          </button>
          <button (click)="pagination.sortBy = 'createdAt'; pagination.direction = 'asc'; loadContent()"
                  ngbDropdownItem>
            Oldest First
          </button>
          <button (click)="pagination.sortBy = 'updatedAt'; pagination.direction = 'desc'; loadContent()"
                  ngbDropdownItem>
            Recently Edited
          </button>
          <button (click)="pagination.sortBy = 'id'; loadContent()" ngbDropdownItem>
            ID
          </button>
          <button (click)="pagination.sortBy = 'title'; loadContent()" ngbDropdownItem>
            Title
          </button>
          <button (click)="pagination.sortBy = 'owner'; loadContent()" ngbDropdownItem>
            Author
          </button>
        </div>
      </div>
      <div class="mx-1">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input (change)="loadContent()"
                   [(ngModel)]="pagination.direction"
                   class="form-check-input"
                   name="sortDirection"
                   type="radio"
                   value="asc"/>
            Ascending
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input (change)="loadContent()"
                   [(ngModel)]="pagination.direction"
                   class="form-check-input"
                   name="sortDirection"
                   type="radio"
                   value="desc"/>
            Descending
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="mode === 'table'; else gridMode">
  <div class="card card-body">
    <table class="table table-hover">
      <thead>
      <tr>
        <th *ngIf="showColumn('TYPE')">
          <span class="visually-hidden">Content Type</span>
        </th>
        <th *ngIf="showColumn('TITLE')">Title</th>
        <th *ngIf="showColumn('DESCRIPTION')">Description</th>
        <th *ngIf="showColumn('AUTHOR')">Author</th>
        <th *ngIf="showColumn('PUBLISHED')">Published</th>
        <th *ngIf="showColumn('CREATED')">Created</th>
        <th *ngIf="showColumn('UPDATED')">Updated</th>
        <th>
          <span class="visually-hidden">Action</span>
        </th>
      </tr>
      </thead>
      <tbody *ngIf="!contentLoading; else spinnerTbody">
      <ng-container *ngFor="let data of contentData">
        <tr>
          <td *ngIf="showColumn('TYPE')" [attr.data-bs-target]="'#contentRow' + data.indexId" data-bs-toggle="collapse">
            <div [ngbTooltip]="data.materialType | titlecase" class="d-inline-block">
              <app-content-icon [materialType]="data.materialType"></app-content-icon>
            </div>
          </td>
          <td *ngIf="showColumn('TITLE')" class="align-middle">
            <span class="fw-bold">{{ data.title }}</span>
            <span *ngFor="let technologyTag of data.technologies | slice:0:5"
                  [ngClass]=" {'bg-primary': technologyTag === awesTag, 'bg-info': technologyTag !== awesTag}"
                  class="badge bg-info text-white rounded-pill mx-1">
              {{ technologyTag }}
            </span>
            <ng-container *ngIf="isExpanded(data.indexId!)">
              <span *ngFor="let technologyTag of data.technologies | slice:5"
                    [ngClass]=" {'bg-primary': technologyTag === awesTag, 'bg-info': technologyTag !== awesTag}"
                    class="badge bg-info text-white rounded-pill mx-1">
                {{ technologyTag }}
              </span>
            </ng-container>
            <span (click)="toggleTags(data.indexId!)" *ngIf="data.technologies!.length > 5"
                  class="badge bg-blue text-white rounded-pill mx-1" style="cursor: pointer;">
              <ng-container *ngIf="!isExpanded(data.indexId!)">(+{{ data.technologies!.length - 5 }}
                more)</ng-container>
              <ng-container *ngIf="isExpanded(data.indexId!)">(Show less)</ng-container>
            </span>
          </td>
          <td *ngIf="showColumn('DESCRIPTION')" [attr.data-bs-target]="'#contentRow' + data.indexId"
              class="align-middle"
              data-bs-toggle="collapse">
            <div *ngIf="data.description; else noDescription">
              {{ data.description | truncated: 100 }}
            </div>
            <ng-template #noDescription>
              <div class="text-muted fst-italic">No description</div>
            </ng-template>
          </td>
          <td *ngIf="showColumn('AUTHOR')" [attr.data-bs-target]="'#contentRow' + data.indexId" class="align-middle"
              data-bs-toggle="collapse">{{ data.createdBy }}
          </td>
          <td *ngIf="showColumn('PUBLISHED')" [attr.data-bs-target]="'#contentRow' + data.indexId"
              class="align-middle text-start"
              data-bs-toggle="collapse">
            <div [ngbTooltip]="data.published ? 'Published' : 'Unpublished'" class="d-inline-block px-2">
              <span *ngIf="data.published" class="bi bi-check2-circle text-success"></span>
              <span *ngIf="!data.published" class="bi bi-x-circle text-warning"></span>
            </div>
          </td>
          <td *ngIf="showColumn('CREATED')" [attr.data-bs-target]="'#contentRow' + data.indexId"
              class="align-middle text-muted"
              data-bs-toggle="collapse">{{ data.createdAt | date: 'MM/dd/yyyy' }}
          </td>
          <td *ngIf="showColumn('UPDATED')" [attr.data-bs-target]="'#contentRow' + data.indexId"
              class="align-middle text-muted"
              data-bs-toggle="collapse">{{ data.updatedAt | date: 'MM/dd/yyyy' }}
          </td>
          <td *ngIf="showColumn('ACTION')" class="align-middle">
            <div ngbDropdown>
              <button class="btn btn-sm btn-outline-primary border-0 dropdown-no-caret" ngbDropdownToggle>
                <span class="visually-hidden">Action</span>
                <span class="bi bi-three-dots-vertical"></span>
              </button>
              <div ngbDropdownMenu>
                <button (click)="doAction('preview', data)" ngbDropdownItem>
                  <span class="bi bi-eye me-2"></span>
                  Preview
                </button>
                <button (click)="doAction('edit', data)" ngbDropdownItem>
                  <span class="bi bi-pencil-square me-2"></span>
                  Edit
                </button>
                <app-assign-learning-material-modal-button *ngIf="data.assignable" [content]="data">
                  <ng-template let-openModal="openModal">
                    <button (click)="openModal()" *ngIf="data.assignable" ngbDropdownItem>
                      <span class="bi bi-person-plus me-2"></span>
                      Assign
                    </button>
                  </ng-template>
                </app-assign-learning-material-modal-button>

                <button (click)="doAction('duplicate', data)" ngbDropdownItem>
                  <span class="bi bi-copy me-2"></span>
                  Duplicate
                </button>

                <div class="dropdown-divider"></div>
                <button (click)="doAction('delete', data)" class="text-danger" ngbDropdownItem>
                  <span class="bi bi-trash me-2"></span>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="showColumn('DETAILS')" class="p-0">
          <td [colSpan]="8" class="p-0 border-0">
            <div [id]="'contentRow' + data.indexId" class="collapse border-bottom">
              <div class="container-fluid pb-5">
                <div class="mx-3">
                  <div class="my-2 row gap-1 col-12 col-md-6 col-xl-4">
                    <button (click)="doAction('preview', data)" class="btn btn-sm btn-outline-primary col"
                            type="button">
                      <span class="bi bi-eye me-1"></span>
                      Preview
                    </button>
                    <button (click)="doAction('edit', data)" class="btn btn-sm btn-outline-primary col" type="button">
                      <span class="bi bi-pencil-square me-1"></span>
                      Edit
                    </button>
                    <div *ngIf="data.assignable" class="col px-0">
                      <app-assign-learning-material-modal-button *ngIf="data.assignable" [content]="data">
                        <ng-template let-openModal="openModal">
                          <button (click)="openModal()" class="btn btn-sm btn-outline-primary w-100" type="button">
                            <span class="bi bi-person-plus me-1"></span>
                            Assign
                          </button>
                        </ng-template>
                      </app-assign-learning-material-modal-button>
                    </div>
                    <button (click)="doAction('delete', data)" class="btn btn-sm btn-danger col" type="button">
                      <span class="bi bi-trash me-1"></span>
                      Delete
                    </button>
                  </div>
                  <div class="row my-2 col-12 col-md-6 col-lg-4">
                    <div class="col mx-0 px-0">
                      <table class="rounded border px-3 py-2 d-block m-0">
                        <tbody>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Title</th>
                          <td class="align-top py-1">{{ data.title }}</td>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Description</th>
                          <td *ngIf="data.description; else noDescriptionCell"
                              class="align-top py-1">{{ data.description | truncated:200 }}
                          </td>
                          <ng-template #noDescriptionCell>
                            <td class="align-top py-1">
                              <span class="fst-italic text-muted">No description</span>
                            </td>
                          </ng-template>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Type</th>
                          <td class="align-top py-1">{{ data.materialType | titlecase }}</td>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Technology Tags</th>
                          <td *ngIf="data.technologies && data.technologies.length; else noTechTags"
                              class="align-top py-1">
                            <span *ngFor="let technologyTag of data.technologies"
                                  [ngClass]=" {'bg-primary': technologyTag === awesTag, 'bg-info': technologyTag !== awesTag}"
                                  class="badge text-white rounded-pill mx-1">
                              {{ technologyTag }}
                            </span>
                          </td>
                          <ng-template #noTechTags>
                            <td class="align-top py-1">
                              <span class="fst-italic text-muted">No technology tags</span>
                            </td>
                          </ng-template>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Published</th>
                          <td class="align-top py-1">
                            {{ data.published ? 'Yes' : 'No' }}
                          </td>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Created by</th>
                          <td class="align-top py-1">
                            {{ data.createdBy }}
                          </td>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Created at</th>
                          <td class="align-top py-1">
                            {{ data.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}
                          </td>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Last updated by</th>
                          <td class="align-top py-1">
                            {{ data.updatedBy }}
                          </td>
                        </tr>
                        <tr>
                          <th class="align-top py-1 pe-3" scope="row">Last updated at</th>
                          <td class="align-top py-1">
                            {{ data.updatedAt | date: 'MM/dd/yyyy hh:mm:ss a' }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <div class="d-flex justify-content-center">
      <ngb-pagination (pageChange)="onPageChange($event)"
                      [(page)]="pagination.page"
                      [boundaryLinks]="true"
                      [collectionSize]="pagination.total"
                      [maxSize]="10"
                      [pageSize]="pagination.size"></ngb-pagination>
    </div>
  </div>
</ng-container>


<ng-template #gridMode>
  Grid Mode
</ng-template>

<ng-template #previewModal>
  <div class="modal-header">
    <h5 class="modal-title">
      Preview {{ previewData.materialData?.materialType | titlecase }}
    </h5>
    <button (click)="closePreviewModal()" aria-label="Close" class="btn-close" type="button"></button>
  </div>
  <div *ngIf="!loadingPreview; else spinner" class="modal-body">
    <div class="px-3">
      <app-assignment-preview *ngIf="previewData.assignment" [data]="previewData.assignment"></app-assignment-preview>
      <app-question-preview *ngIf="previewData.question" [data]="previewData.question"></app-question-preview>
      <app-lesson-preview *ngIf="previewData.lesson" [lessonData]="previewData.lesson"></app-lesson-preview>
      <app-module-tree *ngIf="previewData.module" [moduleData]="previewData.module"></app-module-tree>
      <app-course-tree *ngIf="previewData.course" [courseData]="previewData.course"></app-course-tree>
      <app-quiz-preview *ngIf="previewData.quiz" [data]="previewData.quiz"></app-quiz-preview>
    </div>
  </div>
  <ng-template #spinner>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="modal-footer">
    <button (click)="closePreviewModal()" class="btn btn-outline-primary" type="button">Close Preview</button>
  </div>
</ng-template>

<ng-template #spinnerTbody>
  <tbody>
  <tr>
    <td colspan="8">
      <div class="d-flex justify-content-center my-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
  </tr>
  </tbody>
</ng-template>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Filters <span class="bi bi-filter"></span></h5>
    <button (click)="modal.dismiss()" aria-label="Close" class="btn-close" tabindex="-1" type="button"></button>
  </div>
  <div class="modal-body">
    <div class="my-3">
      <label class="mb-2" for="typeSelector">Material Types:</label>
      <app-multi-select-field [(ngModel)]="contentTypes"
                              [minCount]="1"
                              [options]="typesOptions"
                              [small]="true"
                              id="typeSelector"></app-multi-select-field>
    </div>
    <div class="my-3">
      <div class="mb-2">Technologies:</div>
      <app-technology-tags-field [(ngModel)]="filter.technologies"></app-technology-tags-field>
    </div>
    <div class="my-3">
      <div class="form-check form-switch">
        <input [(ngModel)]="filter.published" class="form-check-input" id="publishedFilter" type="checkbox"
               value="true">
        <label class="form-check-label" for="publishedFilter">Published</label>
      </div>
      <div class="form-check form-switch">
        <input [(ngModel)]="filter.unpublished" class="form-check-input" id="unpublishedFilter" type="checkbox"
               value="false">
        <label class="form-check-label" for="unpublishedFilter">Unpublished</label>
      </div>
      <div class="form-check form-switch">
        <input [(ngModel)]="filter.deprecated" class="form-check-input" id="deprecatedFilter" type="checkbox">
        <label class="form-check-label" for="deprecatedFilter">Deprecated</label>
      </div>
      <div class="form-check form-switch">
        <input [(ngModel)]="filter.notDeprecated" class="form-check-input" id="notDeprecatedFilter" type="checkbox">
        <label class="form-check-label" for="notDeprecatedFilter">Not Deprecated</label>
      </div>
    </div>
    <div class="my-3 text-end">
      <button (click)="resetFilter()"
              class="btn btn-light border" type="button">
        <span class="bi bi-arrow-counterclockwise me-1"></span>
        Reset Filters
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()" class="btn btn-outline-primary" type="button">Cancel</button>
    <button (click)="loadContent(); modal.dismiss()" class="btn btn-primary" type="button">Apply</button>
  </div>
</ng-template>
